@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
.log {
  background: #002a4b;
}
b {
  font-weight: 700;
  font-size: 10px;
}

.next {
  background: lightgray;
}
.posicao {
  position: sticky !important;
  position: -webkit-sticky !important;
  position: -webkit-sticky !important;
  position: -moz-sticky !important;
  position: -ms-sticky !important;
  position: -o-sticky !important;
  top: 5px;
}
.fundoBase {
  background: #ddd;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

.respostas {
  font-size: 12px;
  margin: 10px;
  height: 80px;
}
.opcao {
  width: 100%;
  height: 200px;
  white-space: normal;
  text-align: center;
}

.sel {
  margin: 20px;
}
.st {
  width: 100%;
  padding: 10px;
  margin: 5px;
  white-space: normal;
  text-align: center;
}
.ancora {
}
.default {
  width: 30px;
  text-align: center;
}

.erro {
  width: 30px;
  border: 1px solid red;
}
.base {
  border: 1px solid #000;
  padding: 10px;
}
.participante {
  width: 50px;
  height: 50px;
  border-radius: 500px;
  margin: 20px;
}
.btnPart {
  margin-top: 20px;
}

.perfil {
  border-radius: 20px;
  width: 40px;
}

.active {
  border: solid 4px #000;
}
.inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;

  label {
    padding: 12px 30px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
      background-color: #5562eb;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: "";
      border: 2px solid #d1d7dc;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked ~ label {
    color: #fff;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #54e0c7;
      border-color: #54e0c7;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}

// codepen formatting
.form {
  padding: 0 16px;
  max-width: 550px;
  margin: 50px auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

code {
  background-color: #9aa3ac;
  padding: 0 8px;
}

small {
  font-size: 15px;
}

small b {
  font-size: 15px;
}

.pv {
  font-size: 14px;
  padding: 20px;
  text-align: left;
}
.back {
  background: white !important;
  width: 100% !important;
  outline: #fff;
}

h3 b {
  font-size: 16px;
}

.form-control {
  height: 50px;
}

.item {
  border: solid 1px lightgray;
  display: inline-block;
  width: 100%;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
}

.target {
  text-align: center;
  background: white;
  height: 100%;
  padding-top: 10%;
  border: dashed lightgray 1px;
}

.itensCheck {
  display: none;
}

.minesweeper__restart {
  background: transparent;
  border: dashed 1px #ffd800;
}
.minesweeper__body {
  background: transparent;
  border: 0px !important;

}

@media (max-width: 440px) {
  .minesweeper__body {
    max-width: 380px !important;
  }
}

@media (max-width: 374px) {
  .minesweeper__body {
    max-width: 280px !important;
  }
}

@media (max-width: 270px) {
  .minesweeper__body {
    display: none !important;
  }
}

.minesweeper {
  background: transparent !important;
  border: 0px !important;
}
.collapsa {
  width: 100px;
}

.collapsa.in {
  width: 200px;
}

.login-placeholder::placeholder {
  color: #d7d7d7;
  font-size: 16px;
}

button.close span {
  font-size: 18px !important;
}

.remove-btn-focus-outline button:focus {
  outline: none;
  box-shadow: none;
}