@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
.proof-alternative input {
  opacity:0;
}

.proof-alternative input:checked + label {
  color: white;
  background: #012a4a;
}

#header-logged {
  background-color: #002a4b;
  padding: 0 20px 10px 20px;
  width: 100%;
  border: none;
  box-sizing: border-box;
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0;
}

#header-logged > #userdata {
  height: 50px;
  display: flex;
  align-items: flex-end;
}

#header-logged > #userdata > .usr > img {
  width: 110px;
  height: 110px;
  border: solid 2px white;
  box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
  border-radius: 60px;
  position: relative;
  top: 60px;
  background-size: cover;
  background-position: center center;
  z-index: 1;
  margin: 0 10px 0 0;
}
#header-logged > #userdata > .usrName p {
  color: #ffffff;
  padding-top: 0;
  /* width: calc(100vw - 140px); */
  line-height: 18px;
  height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  margin: 0;
}

#header-logged > #logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

#header-logged > #logo-wrapper > .lgt {
  margin-left: -80px;
  font-size: 16px;
  margin-top: 2px;
  color: white;
  text-decoration: underline;
  position: absolute;
  cursor: pointer;
}

#header-logged > .bloco > img {
  margin-top: 0px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .react-joyride__overlay {
    width: 110%;
  }
}

@media (max-width: 450px) {
  #header-logged {
    width: 110%;
  }

  #header-logged > #logo-wrapper > .lgt {
    margin-left: -50px;
  }
}


@media screen and (max-width: 566px) {
  #header-logged {
    justify-content: start;
  }
  #header-logged > #userdata {
    width: 190px;
  }

  #header-logged > #logo-wrapper {
    width: calc(100vw - 230px);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
  }

  #header-logged > #logo-wrapper > .lgt {
    position: absolute;
    left: 0;
  }

  #header-logged > #logo-wrapper > .lgt2 {
    position: absolute;
    left: 10 !important;
  }

  #header-logged > #logo-wrapper > #logo {
    margin-top: 0px;
  }

  #header-logged > #userdata > .usrName > p {
    max-width: 300px;
  }

  #header-logged > #userdata > .usrName > p {
    color: #000000;
    padding-top: 0;
    width: calc(100vw - 140px);
    line-height: 18px;
    height: 36px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  #header-logged > #userdata > .usrName {
    position: relative;
    top: 55px;
  }
}

span.preview-title {
  font-size: 30px;
}


@media (max-width: 821px) {
  #logo-wrapper #logo {
    height: 40px;
    width: 40px;
  }
}

.tests-labels {
  margin-right: 100px;
  text-align: center;
  color: #fff;
}

@media (max-width: 1700px) {
  .logo-div {
    margin-left: 130px;
  }
}

@media (max-width: 990px) {
  .preview-label {
    font-size: 12px;
  }
}

@media (max-width: 1700px) {
  .tests-labels-intro {
    margin-left: 280px !important;
  }
}

@media (max-width: 976px) {
  .logo-div {
    display: none !important;
    margin-left: 0 !important;
  }

  .tutorial-span {
    margin-left: 0 !important;
    padding-left: 70px !important;
  }
}

@media (max-width: 930px) {
  .header-wrapper-logged {
    flex-direction: column !important;
  }
  .logo-div {
    display: none !important;
  }

  .tests-labels {
    margin-top: 30px !important;
  }

  .header-buttons-wrapper {
    height: 40px !important;
    justify-content: center !important;
  }

  .intro-header {
    flex-direction: column !important;
    width: 108% !important;
  }

  .tests-labels-intro {
    margin-left: 48px !important;
  }

  .preview-title {
    font-size: 20px !important;
    white-space: nowrap !important;
  }

  .tests-labels {
    margin-right: 0 !important;
  }

  .tests-wrapper {
    margin-left: 0 !important;
  }

  .user-infos {
    white-space: normal !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .user-avatar-header {
    margin-bottom: 20px;
  }
}

.user-greet-avatar:hover {
    border: 4px solid #002848 !important;

    transition: all 0.1s ease !important;
}

@media (max-width: 645px) {
    .user-avatar-modal .modal-content {
        width: 93% !important;
    }
    .imagemFoto {
        max-width: 280px !important;
        max-height: 280px !important;
    }
}
.responsive-image img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 976px) {
    .responsive-image img {
        max-width: 100% !important;
        height: auto !important;
    }
}

@media (max-width: 576px) {
    .responsive-image img {
        width: 100% !important;
        height: auto !important;
    }
}

option:disabled {
    color: rgba(0, 0, 0, 0.185);
}
div.AvaliacaoBinaria_choosenOption___UXam {
    position: absolute;
    top: 30%;
    left: 0;
    width: 60%;
    text-align: left;
    color: #fafafa;
    padding-left: 50px;
    font-weight: bolder;
    font-size: 1.5rem;
    text-transform: uppercase;


    background: linear-gradient(to left, #61ae7f 60%, transparent);
}

.AvaliacaoBinaria_modal-open__CzOXw {
    overflow: visible !important;
    padding: 0;
}

span.AvaliacaoBinaria_bold__3vas7 {
    font-weight: bolder;
}

@media (max-width: 990px) {
    div.AvaliacaoBinaria_choosenOption___UXam {
        width: 100%;
    }
}

@media (max-width: 660px) {
    div.AvaliacaoBinaria_choosenOption___UXam {
        width: 100% !important;
        font-size: 1.4rem;
    }
}

div.AvaliacaoBinaria_choosenOption__PNOTc {
    position: absolute;
    top: 30%;
    left: 0;
    width: 60%;
    text-align: left;
    color: #fafafa;
    padding-left: 50px;
    font-weight: bolder;
    font-size: 1.5rem;
    text-transform: uppercase;


    background: linear-gradient(to left, #61ae7f 60%, transparent);
}

.AvaliacaoBinaria_modal-open__jnwp8 {
    overflow: visible !important;
    padding: 0;
}

span.AvaliacaoBinaria_bold__2cQhC {
    font-weight: bolder;
}

@media (max-width: 990px) {
    div.AvaliacaoBinaria_choosenOption__PNOTc {
        width: 100%;
    }
}

@media (max-width: 660px) {
    div.AvaliacaoBinaria_choosenOption__PNOTc {
        width: 100% !important;
        font-size: 1.4rem;
    }
}

.responsive-image img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 976px) {
    .responsive-image img {
        max-width: 100% !important;
        height: auto !important;
    }
}

@media (max-width: 576px) {
    .responsive-image img {
        width: 100% !important;
        height: auto !important;
    }
}

option:disabled {
    color: rgba(0, 0, 0, 0.185);
}
#tempo-questao-wrapper {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.font-size16 p {
  font-size: 16px;
}
.small-screen {
  display: none;
}

#enunciado {
  padding: 0 15px 0 15px;
}

@media screen and (max-width: 566px) {
  .small-screen {
    display: flex;
    background-color: white;
    width: 80vw;
    height: auto;
    padding: 0;
  }

  .col-sm-12.small-screen,
  #tempo-questao > .row > .col-sm-12 {
    padding: 0;
  }

  .small-screen > #tempo-questao {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  #tempo-questao > .row {
    margin: 0;
    width: 100%;
  }

  .font-button {
    margin: 0 20px 0 0;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .bigger-screen {
    display: none;
  }
  #enunciado {
    margin-left: 1vw;
    width: 87vw;
  }
}

#tempo-questao-wrapper {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.font-size16 p {
  font-size: 16px;
}
.small-screen {
  display: none;
}

#enunciado {
  padding: 0 15px 0 15px;
}

@media screen and (max-width: 566px) {
  .small-screen {
    display: flex;
    background-color: white;
    width: 80vw;
    height: auto;
    padding: 0;
  }

  .col-sm-12.small-screen,
  #tempo-questao > .row > .col-sm-12 {
    padding: 0;
  }

  .small-screen > #tempo-questao {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  #tempo-questao > .row {
    margin: 0;
    width: 100%;
  }

  .font-button {
    margin: 0 20px 0 0;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .bigger-screen {
    display: none;
  }
  #enunciado {
    margin-left: 1vw;
    width: 87vw;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
.log {
  background: #002a4b;
}
b {
  font-weight: 700;
  font-size: 10px;
}

.next {
  background: lightgray;
}
.posicao {
  position: sticky !important;
  position: -webkit-sticky !important;
  position: -webkit-sticky !important;
  position: -moz-sticky !important;
  position: -ms-sticky !important;
  position: -o-sticky !important;
  top: 5px;
}
.fundoBase {
  background: #ddd;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

.respostas {
  font-size: 12px;
  margin: 10px;
  height: 80px;
}
.opcao {
  width: 100%;
  height: 200px;
  white-space: normal;
  text-align: center;
}

.sel {
  margin: 20px;
}
.st {
  width: 100%;
  padding: 10px;
  margin: 5px;
  white-space: normal;
  text-align: center;
}
.ancora {
}
.default {
  width: 30px;
  text-align: center;
}

.erro {
  width: 30px;
  border: 1px solid red;
}
.base {
  border: 1px solid #000;
  padding: 10px;
}
.participante {
  width: 50px;
  height: 50px;
  border-radius: 500px;
  margin: 20px;
}
.btnPart {
  margin-top: 20px;
}

.perfil {
  border-radius: 20px;
  width: 40px;
}

.active {
  border: solid 4px #000;
}
.inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;

  label {
    padding: 12px 30px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
      background-color: #5562eb;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: "";
      border: 2px solid #d1d7dc;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked ~ label {
    color: #fff;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #54e0c7;
      border-color: #54e0c7;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}

// codepen formatting
.form {
  padding: 0 16px;
  max-width: 550px;
  margin: 50px auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

code {
  background-color: #9aa3ac;
  padding: 0 8px;
}

small {
  font-size: 15px;
}

small b {
  font-size: 15px;
}

.pv {
  font-size: 14px;
  padding: 20px;
  text-align: left;
}
.back {
  background: white !important;
  width: 100% !important;
  outline: #fff;
}

h3 b {
  font-size: 16px;
}

.form-control {
  height: 50px;
}

.item {
  border: solid 1px lightgray;
  display: inline-block;
  width: 100%;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
}

.target {
  text-align: center;
  background: white;
  height: 100%;
  padding-top: 10%;
  border: dashed lightgray 1px;
}

.itensCheck {
  display: none;
}

.minesweeper__restart {
  background: transparent;
  border: dashed 1px #ffd800;
}
.minesweeper__body {
  background: transparent;
  border: 0px !important;

}

@media (max-width: 440px) {
  .minesweeper__body {
    max-width: 380px !important;
  }
}

@media (max-width: 374px) {
  .minesweeper__body {
    max-width: 280px !important;
  }
}

@media (max-width: 270px) {
  .minesweeper__body {
    display: none !important;
  }
}

.minesweeper {
  background: transparent !important;
  border: 0px !important;
}
.collapsa {
  width: 100px;
}

.collapsa.in {
  width: 200px;
}

.login-placeholder::placeholder {
  color: #d7d7d7;
  font-size: 16px;
}

button.close span {
  font-size: 18px !important;
}

.remove-btn-focus-outline button:focus {
  outline: none;
  box-shadow: none;
}
