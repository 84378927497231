.user-greet-avatar:hover {
    border: 4px solid #002848 !important;

    transition: all 0.1s ease !important;
}

@media (max-width: 645px) {
    .user-avatar-modal .modal-content {
        width: 93% !important;
    }
    .imagemFoto {
        max-width: 280px !important;
        max-height: 280px !important;
    }
}