.responsive-image img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 976px) {
    .responsive-image img {
        max-width: 100% !important;
        height: auto !important;
    }
}

@media (max-width: 576px) {
    .responsive-image img {
        width: 100% !important;
        height: auto !important;
    }
}

option:disabled {
    color: rgba(0, 0, 0, 0.185);
}