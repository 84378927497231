div.choosenOption {
    position: absolute;
    top: 30%;
    left: 0;
    width: 60%;
    text-align: left;
    color: #fafafa;
    padding-left: 50px;
    font-weight: bolder;
    font-size: 1.5rem;
    text-transform: uppercase;


    background: linear-gradient(to left, #61ae7f 60%, transparent);
}

.modal-open {
    overflow: visible !important;
    padding: 0;
}

span.bold {
    font-weight: bolder;
}

@media (max-width: 990px) {
    div.choosenOption {
        width: 100%;
    }
}

@media (max-width: 660px) {
    div.choosenOption {
        width: 100% !important;
        font-size: 1.4rem;
    }
}
