#tempo-questao-wrapper {
  height: fit-content;
  z-index: 1;
  position: sticky;
  top: 0;
}
.font-size16 p {
  font-size: 16px;
}
.small-screen {
  display: none;
}

#enunciado {
  padding: 0 15px 0 15px;
}

@media screen and (max-width: 566px) {
  .small-screen {
    display: flex;
    background-color: white;
    width: 80vw;
    height: auto;
    padding: 0;
  }

  .col-sm-12.small-screen,
  #tempo-questao > .row > .col-sm-12 {
    padding: 0;
  }

  .small-screen > #tempo-questao {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  #tempo-questao > .row {
    margin: 0;
    width: 100%;
  }

  .font-button {
    margin: 0 20px 0 0;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .bigger-screen {
    display: none;
  }
  #enunciado {
    margin-left: 1vw;
    width: 87vw;
  }
}
